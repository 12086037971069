// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/PhotoGallery/DSC09612-Edit.webp", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app__documents {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  background: linear-gradient(0deg, rgba(190, 190, 190, 0.9) 0%, rgba(190, 190, 190, 0.9) 50%, rgba(190, 190, 190, 0.9) 100%), url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 4rem 5rem 4rem;
  width: 100%;
}
@media screen and (max-width: 990px) {
  .app__documents {
    padding: 1rem 2rem 1rem;
  }
  .app__documents .app__documentCard-id {
    display: none;
  }
  .app__documents .app__documentCard-text {
    font-size: 0.9rem;
    padding: 0.5rem;
  }
  .app__documents .app__documentCard-img {
    padding: 0.7rem;
    width: 4rem;
  }
}
.app__documents-title {
  width: 100%;
  color: var(--white-color);
  font-size: 1.5rem;
  text-transform: uppercase;
  font-weight: 800;
  margin-bottom: 2rem;
}
@media screen and (min-width: 990px) {
  .app__documents-item:nth-child(2n) {
    width: 70%;
  }
  .app__documents-item:nth-child(1) {
    width: 60%;
  }
  .app__documents-item:nth-child(5) {
    width: 60%;
  }
}
.app__documents-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/container/Documents/Documents.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,eAAA;EACA,kBAAA;EACA,oKAAA;EACA,sBAAA;EACA,2BAAA;EACA,4BAAA;EACA,uBAAA;EACA,WAAA;AACJ;AACI;EAXJ;IAYQ,uBAAA;EAEN;EAAM;IACI,aAAA;EAEV;EACM;IACI,iBAAA;IACA,eAAA;EACV;EAEM;IACI,eAAA;IACA,WAAA;EAAV;AACF;AAGI;EACI,WAAA;EACA,yBAAA;EACA,iBAAA;EACA,yBAAA;EACA,gBAAA;EACA,mBAAA;AADR;AAII;EAEI;IACI,UAAA;EAHV;EAMM;IACI,UAAA;EAJV;EAOM;IACI,UAAA;EALV;AACF;AAQI;EACI,WAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AANR","sourcesContent":[".app__documents { \n    display: flex;\n    flex-wrap: wrap;\n    position: relative;\n    background: linear-gradient(0deg, rgba(190, 190, 190, 0.9) 0%, rgba(190, 190, 190, 0.9) 50%, rgba(190, 190, 190, 0.9) 100%),url(\"../../assets/PhotoGallery/DSC09612-Edit.webp\");\n    background-size: cover;\n    background-position: center;\n    background-repeat: no-repeat;\n    padding: 4rem 5rem 4rem;\n    width: 100%;\n\n    @media screen and (max-width: 990px) {\n        padding: 1rem 2rem 1rem;\n\n        .app__documentCard-id {\n            display: none;\n        }\n\n        .app__documentCard-text {\n            font-size: 0.9rem;\n            padding: 0.5rem;\n        }\n\n        .app__documentCard-img {\n            padding: 0.7rem;\n            width: 4rem;\n        }\n    }\n\n    &-title {\n        width: 100%;\n        color: var(--white-color);\n        font-size: 1.5rem;\n        text-transform: uppercase;\n        font-weight: 800;\n        margin-bottom: 2rem;\n    }\n\n    @media screen and (min-width: 990px) {\n\n        &-item:nth-child(2n) {\n            width: 70%;\n        }\n    \n        &-item:nth-child(1) {\n            width: 60%;\n        }\n    \n        &-item:nth-child(5) {\n            width: 60%;\n        }\n    }\n\n    &-list {\n        width: 100%;\n        display: flex;\n        flex-direction: column;\n        gap: 1rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
