// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app__photoGallery {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
}
.app__photoGallery .flicking-panel {
  height: 780px;
  width: 100%;
}
.app__photoGallery-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.app__photoGallery .swiper-pagination .swiper-pagination-bullet-active {
  background-color: var(--secondary-color);
}
.app__photoGallery .swiper-button-next {
  color: var(--secondary-color);
}
.app__photoGallery .swiper-button-prev {
  color: var(--secondary-color);
}
@media screen and (max-width: 450px) {
  .app__photoGallery .is-circle {
    background-color: transparent;
  }
  .app__photoGallery .flicking-panel {
    height: 580px;
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/container/PhotoGallery/PhotoGallery.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,eAAA;EACA,kBAAA;AACJ;AACI;EACI,aAAA;EACA,WAAA;AACR;AAEI;EACI,WAAA;EACA,YAAA;EACA,iBAAA;AAAR;AAIQ;EACI,wCAAA;AAFZ;AAMI;EACI,6BAAA;AAJR;AAOI;EACI,6BAAA;AALR;AAQI;EACI;IACI,6BAAA;EANV;EASM;IACI,aAAA;IACA,WAAA;EAPV;AACF","sourcesContent":[".app__photoGallery {\n    display: flex;\n    align-items: center;\n    flex-wrap: wrap;\n    position: relative;\n    \n    .flicking-panel {\n        height: 780px;\n        width: 100%;\n    }\n\n    &-img {\n        width: 100%;\n        height: 100%;\n        object-fit: cover;\n    }\n\n    .swiper-pagination {\n        .swiper-pagination-bullet-active {\n            background-color: var(--secondary-color);\n        }\n    }\n\n    .swiper-button-next {\n        color: var(--secondary-color);\n    }\n\n    .swiper-button-prev {\n        color: var(--secondary-color);\n    }\n\n    @media screen and (max-width: 450px) {\n        .is-circle {\n            background-color: transparent;\n        }\n\n        .flicking-panel {\n            height: 580px;\n            width: 100%;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
