// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LearningStagesCart {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  align-items: center;
  height: 21rem;
  background-size: cover;
  background-position: center;
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
}
.LearningStagesCart .title {
  padding: 0.2rem;
  margin-top: 2rem;
  background-color: rgba(255, 128, 0, 0.9);
  color: var(--white-color);
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  width: 100%;
  min-height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.LearningStagesCart .content {
  padding: 2rem;
}`, "",{"version":3,"sources":["webpack://./src/components/LearningStageCard/LearningStagesCart.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,SAAA;EACA,sBAAA;EACA,mBAAA;EACA,aAAA;EACA,sBAAA;EACA,2BAAA;EACA,YAAA;EACA,iBAAA;EACA,iBAAA;EACA,kBAAA;AACJ;AAEI;EACE,eAAA;EACA,gBAAA;EACA,wCAAA;EACA,yBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,WAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAAN;AAGI;EACE,aAAA;AADN","sourcesContent":[".LearningStagesCart {\n    display: flex;\n    flex: 1;\n    flex-direction: column;\n    align-items: center;\n    height: 21rem;\n    background-size: cover;\n    background-position: center;\n    color: white;\n    font-size: 1.5rem;\n    font-weight: bold;\n    text-align: center;\n    \n  \n    .title {\n      padding: 0.2rem;\n      margin-top: 2rem;\n      background-color: rgba(255, 128, 0, 0.9);\n      color: var(--white-color);\n      font-size: 1rem;\n      font-weight: bold;\n      text-align: center;\n      width: 100%;\n      min-height: 5rem;\n      display: flex;\n      align-items: center;\n      justify-content: center;\n    }\n  \n    .content {\n      padding: 2rem;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
