import React from 'react'
import { images } from '../../constants';
import { PriceCard } from '../../components';
import SwiperCore, { Pagination, Navigation, HashNavigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";


import './Prices.scss';

const Prices = () => {
  const prices = [{
    title: 'Курс автошколи',
    priceTitle: 'Вартість',
    oldPrice: '9 000',
    price: '8 000 грн',
    subPrices: [
      'теоретичний курс',
      'медичний курс',
      'практичний курс'
    ],
    buttonText: 'Деталі',
    imageSrc: images.priceLecturePhoto
  },
  {
    title: 'Практичний курс',
    desc: '(після самопідготовки)',
    priceTitle: 'обовʼязкових',
    oldPrice: '',
    price: '40 годин',
    subPrices: [
      'механіка 650 грн (60хв)', 
      'автомат 800 грн (60хв)'],
    buttonText: 'Деталі',
    imageSrc: images.priceDrivingLessonPhoto
  },
  {
    title: 'Велокурс',
    priceTitle: 'Вартість',
    oldPrice: '',
    price: '4 000 грн',
    subPrices: [
      'теоретичний курс',
      'медичний курс'
    ],
    buttonText: 'Деталі',
    imageSrc: images.priceBicyclePhoto
  }];

  SwiperCore.use([Pagination, Navigation, HashNavigation]);
  

  return (
    <div className='app__prices' name='prices'>
      <p className='app__prices__title'>Ціни</p>
      <div className='app__prices-list'>
        {prices.map((price, index) => (
            <div key={index} className="card-panel">
                <PriceCard
                  index={index}
                  title={price.title}
                  desc={price.desc}
                  priceTitle={price.priceTitle}
                  oldPrice={price.oldPrice}
                  price={price.price}
                  subPrices={price.subPrices}
                  buttonText={price.buttonText}
                  imageSrc={price.imageSrc}
                />
            </div>
        ))}
      </div>
      <div className='app__prices-list-mobile'>
      <Swiper
        cssMode={true}
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        className="mySwiper">
        {prices.map((price, index) => (
          <SwiperSlide className="card-panel">
            <PriceCard
              index={index}
              title={price.title}
              desc={price.desc}
              priceTitle={price.priceTitle}
              oldPrice={price.oldPrice}
              price={price.price}
              subPrices={price.subPrices}
              buttonText={price.buttonText}
              imageSrc={price.imageSrc}
            />
            </SwiperSlide>
          ))}
      </Swiper>
      </div>
    </div>
  )
}

export default Prices