import React, { useState } from 'react';
import { HiMenuAlt4, HiX } from 'react-icons/hi';
import { motion, AnimatePresence } from 'framer-motion';
import { Link as ScrollLink } from 'react-scroll';
import { Link as RouterLink, useLocation } from 'react-router-dom'; // Додано useLocation для визначення поточної сторінки

import { images } from '../../constants';
import './Navbar.scss';

const Navbar = ({ isHidden }) => {
  const [toggle, setToggle] = useState(false);
  const location = useLocation(); // Отримуємо поточний шлях сторінки

  // Масив пунктів меню
  const menuItems = [
    {
      text: "про нас",
      section: "/about", // Шлях до сторінки "about"
      isRoute: true // Позначає, що це маршрут, а не секція
    },
    {
      text: "наші переваги",
      section: "advantages",
      isRoute: false
    },
    {
      text: "навчання",
      section: "learning",
      isRoute: false
    },
    {
      text: "ціни",
      section: "prices",
      isRoute: false
    },
    {
      text: "документи",
      section: "documents",
      isRoute: false
    }
  ];

  // Перевіряємо, чи ми на головній сторінці
  const isHomePage = location.pathname === '/';

  return (
    <nav className="app__navbar">
      {/* Логотип, який повертає на головну сторінку */}
      <div className="app__navbar-logo">
        <RouterLink to="/">
          <img src={images.logo} loading="lazy" alt="logo" />
        </RouterLink>
      </div>

      {/* Основне меню для десктопу */}
      {isHomePage && ( // Відображаємо меню тільки на головній сторінці
        <ul className="app__navbar-links">
          {menuItems.map((item, index) => (
            <li className="app__flex p-text" key={index}>
              <div></div>
              {item.isRoute ? (
                // Використовуємо RouterLink для навігації на сторінку "about"
                <RouterLink to={item.section}>{item.text}</RouterLink>
              ) : (
                // Використовуємо ScrollLink для прокрутки до секцій
                <ScrollLink to={item.section} spy={true} smooth={true} duration={1000}>
                  {item.text}
                </ScrollLink>
              )}
            </li>
          ))}
        </ul>
      )}

      {/* Мобільне меню */}
      {isHidden || !isHomePage ? null : ( // Приховуємо мобільне меню, якщо isHidden або не на головній сторінці
        <div className="app__navbar-menu">
          <HiMenuAlt4 onClick={() => setToggle(true)} />

          <AnimatePresence>
            {toggle && (
              <motion.div
                key="menu"
                initial={{ opacity: 0, x: 300 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: 300 }}
                transition={{ duration: 0.5, ease: 'easeOut' }}
              >
                <HiX onClick={() => setToggle(false)} />
                <ul>
                  {menuItems.map((item, index) => (
                    <li key={index}>
                      {item.isRoute ? (
                        // Використовуємо RouterLink для навігації на сторінку "about"
                        <RouterLink to={item.section} onClick={() => setToggle(false)}>
                          {item.text}
                        </RouterLink>
                      ) : (
                        // Використовуємо ScrollLink для прокрутки до секцій
                        <ScrollLink
                          to={item.section}
                          spy={true}
                          smooth={true}
                          duration={1000}
                          offset={-100}
                          onClick={() => setToggle(false)}
                        >
                          {item.text}
                        </ScrollLink>
                      )}
                    </li>
                  ))}
                </ul>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      )}
    </nav>
  );
};

export default Navbar;