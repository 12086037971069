import React, { useContext } from 'react'
import { motion } from 'framer-motion';

import { images } from '../../constants';
import { Contacts } from '../../components';
import './Header.scss';
import AppContext from '../../context/AppContext'

const Header = () => {
  const { toggleVisibility } = useContext(AppContext);

  const cardVariants = {
    offscreen: {
      y: 30,
      scale: 0.2
    },
    onscreen: {
      y: 0,
      scale: 1,
      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 2
      }
    }
  };

  return (
  <div id='home' name='header'>
    {/* SEO text hidden visually but readable by search engines */}
    <div style={{ 
      position: 'absolute', 
      width: '1px', 
      height: '1px', 
      padding: '0', 
      margin: '-1px', 
      overflow: 'hidden', 
      clip: 'rect(0, 0, 0, 0)', 
      whiteSpace: 'nowrap', 
      border: '0' 
    }} 
    role="presentation">
      Автошкола Львів, автошкола у Львові, курси водіння, курси водіїв, автокурси, автокурси онлайн, 
      отримати водійське посвідчення, запис в автошколу, автокурси онлайн, уроки водіння, отримати права, 
      навчання в автошколі
    </div>

    <motion.div
      className="app__header app__flex" 
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: false, amount: 0.5 }}>
      <motion.div
        variants={cardVariants}
        className="app__header-info"
      >
        <div className="app__header-badge">
          <div className="badge-cmp app__flex">
            <h1 className="head-text">Знижка всім -3000 ГРН</h1>
          </div>

          <div className="tag-cmp app__flex">
            <p className="p-text">при виборі пакету експерт</p>
            <motion.div
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.5, type: 'tween' }}
              whileHover={{ scale: 1.1 }}
              className="app__contacts-item"
            >
              <button onClick={toggleVisibility}>Залишити заявку</button>
            </motion.div>
            {/*<span style={{color: "white"}}>-1000 Грн</span>*/}
            {/*<p className="p-text-students">знижка для студентів 500 гривень</p>*/}
          </div>
        </div>
      </motion.div>

      <div className="app__header-img">
        <img src={images.maincar} alt="profile_bg" loading="lazy"/>
        <motion.img
          variants={cardVariants}
          src={images.bigLetterY}
          alt="profile_circle"
          className="overlay_circle"
        />
      </div>
    </motion.div>
    <Contacts/>
  </div>);
}

export default Header;