// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app__discount {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  background-color: black;
}

.app__discount-item {
  width: 275px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin: 1rem;
  color: var(--white-color);
}
@media screen and (min-width: 2000px) {
  .app__discount-item {
    width: 370px;
    margin: 2rem 4rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/container/Discount/Discount.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,6BAAA;EACA,mBAAA;EACA,eAAA;EACA,uBAAA;AACJ;;AAEA;EACI,YAAA;EACA,aAAA;EACA,2BAAA;EACA,mBAAA;EACA,sBAAA;EACA,YAAA;EACA,yBAAA;AACJ;AACI;EATJ;IAUM,YAAA;IACA,iBAAA;EAEJ;AACF","sourcesContent":[".app__discount {\n    display: flex;\n    justify-content: space-around;\n    align-items: center;\n    flex-wrap: wrap;\n    background-color: black;\n}\n\n.app__discount-item {\n    width: 275px;\n    display: flex;\n    justify-content: flex-start;\n    align-items: center;\n    flex-direction: column;\n    margin: 1rem;\n    color: var(--white-color);\n\n    @media screen and (min-width: 2000px) {\n      width: 370px;\n      margin: 2rem 4rem;\n    }\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
