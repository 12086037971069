// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.region-block {
  background-color: var(--white-color);
  border-radius: 6px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
}
.region-block .region-title {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
  color: var(--brown-color);
}
.region-block .region-info {
  margin-bottom: 1rem;
  line-height: 1.5;
  text-align: justify;
}
.region-block .region-list {
  list-style: disc inside;
  margin: 0;
  padding: 0;
}
.region-block .region-list li {
  margin-bottom: 0.5rem;
  color: var(--gray-color);
  font-size: 0.95rem;
}`, "",{"version":3,"sources":["webpack://./src/components/RegionBlock/RegionBlock.scss"],"names":[],"mappings":"AAAA;EACI,oCAAA;EACA,kBAAA;EACA,0CAAA;EACA,eAAA;EACA,aAAA;EACA,sBAAA;AACJ;AACI;EACE,iBAAA;EACA,qBAAA;EACA,gBAAA;EACA,yBAAA;AACN;AAEI;EACE,mBAAA;EACA,gBAAA;EACA,mBAAA;AAAN;AAGI;EACE,uBAAA;EACA,SAAA;EACA,UAAA;AADN;AAGM;EACE,qBAAA;EACA,wBAAA;EACA,kBAAA;AADR","sourcesContent":[".region-block {\n    background-color: var(--white-color);\n    border-radius: 6px;\n    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);\n    padding: 1.5rem;\n    display: flex;\n    flex-direction: column;\n  \n    .region-title {\n      font-size: 1.2rem;\n      margin-bottom: 0.5rem;\n      font-weight: 600;\n      color: var(--brown-color);\n    }\n  \n    .region-info {\n      margin-bottom: 1rem;\n      line-height: 1.5;\n      text-align: justify;\n    }\n  \n    .region-list {\n      list-style: disc inside;\n      margin: 0;\n      padding: 0;\n  \n      li {\n        margin-bottom: 0.5rem;\n        color: var(--gray-color);\n        font-size: 0.95rem;\n      }\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
