export { default as Advantages } from './Advantages/Advantages'
export { default as Discount } from './Discount/Discount'
export { default as Documents } from './Documents/Documents'
export { default as Footer } from './Footer/Footer'
export { default as Header } from './Header/Header'
export { default as LearningStages } from './LearningStages/LearningStages'
export { default as Locations } from './Locations/Locations'
export { default as PhotoGallery } from './PhotoGallery/PhotoGallery'
export { default as Prices } from './Prices/Prices'
export { default as PricePack } from './PricePack/PricePack'
export { default as SpecialOffer } from './SpecialOffer/SpecialOffer'
export { default as ThankYou } from './ThankYou/ThankYou'
export { default as UpdateForm } from './UpdateForm/UpdateForm'
export { default as About } from './About/About'