import React from "react";
import PropTypes from "prop-types";
import "./RegionBlock.scss";

const RegionBlock = ({ title, info, list }) => {
  return (
    <div className="region-block">
      <h3 className="region-title">{title}</h3>
      <p className="region-info">{info}</p>
      <ul className="region-list">
        {list.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    </div>
  );
};

RegionBlock.propTypes = {
  title: PropTypes.string.isRequired,
  info: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default RegionBlock;
