import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import "./About.scss";
import { RegionBlock } from '../../components';

// Імпорт масиву даних
import { regionsData } from "./regionsData";

const About = () => {
    const { pathname } = useLocation();
    
    useEffect(() => {
        setTimeout(window.scrollTo(0, 0), 100);
    }, [pathname]);

  return (
    <section className="about-page">
      {/* Hero banner */}
      <div className="about-hero">
        <h1 className="about-hero-title">Про нас</h1>
      </div>

      {/* Основний контент (перша "картка") */}
      <div className="about-container">
        <p className="about-text">
          Ласкаво просимо до автошколи <strong>“Таурус”</strong> у Львові! Ми – професійна автошкола Львів, яка допомагає майбутнім водіям посвідчення водія.
        </p>
        <p className="about-text">
          У кожного водія була своя перша поїздка – хвилювання, азарт, нові відчуття. В автошколі <strong>“Таурус”</strong> у Львові ми добре знаємо, як важливо, щоб цей шлях був комфортним, безпечним і наповненим знаннями. Якщо ви шукаєте курси водіння або хочете отримати водійське посвідчення, ми готові вам допомогти!
        </p>
        <p className="about-text highlight">
          Ми працюємо вже не перший рік і випустили сотні водіїв, які зараз впевнено керують авто.
        </p>
        <p className="about-text">
          У нас працюють досвідчені та спокійні інструктори, які підтримають вас на кожному етапі навчання. Вони знають, як пояснити складне простими словами, навчити справлятися зі страхом і впевнено виїжджати в місто.
        </p>
        <p className="about-text">
          Ми пропонуємо автокурси онлайн та очне навчання, працюємо в будні й вихідні, щоб підлаштуватися під ваш графік. Якщо ви плануєте запис в автошколу, у нас є філії в різних районах Львова, тож ви легко знайдете зручне місце для занять. Наш сучасний автопарк дозволяє проходити уроки водіння на комфортних та безпечних авто, як на механіці, так і на автоматі.
        </p>
        <blockquote className="about-quote">
          «Обирайте автокурси <strong>“Таурус”</strong> – і разом ми зробимо вашу першу поїздку легкою, впевненою та безпечною!»
        </blockquote>

        {/*<div className="about-cta">
          <button className="about-button">Записатися на курси</button>
        </div>*/}
      </div>

      {/* Секція з курсами по районах */}
      <section className="courses-section">
        <h2 className="courses-title">
          Курси водіїв у Львові – виберіть зручний район для навчання
        </h2>
        <p className="courses-description">
          Якщо ви шукаєте автошколу у Львові, важливо обрати зручну локацію, де 
          буде комфортно проходити навчання. Ми пропонуємо курси водіння у різних 
          районах міста, щоб кожен міг знайти автошколу поруч із домом чи роботою.
        </p>

        {/* Ітеруємося по масиву даних і виводимо компоненту RegionBlock */}
        <div className="regions-grid">
          {regionsData.map((region, index) => (
            <RegionBlock
              key={index}
              title={region.title}
              info={region.info}
              list={region.list}
            />
          ))}
        </div>

        <p className="courses-final-note">
          Ми пропонуємо навчання у всіх районах Львова, щоб ви могли швидко та 
          зручно отримати водійське посвідчення. Записуйтеся на курси водіння у 
          своєму районі вже сьогодні! Також ви зможете пройти експрес курси 
          водіння у Львові, та закінчити експрес автошколу у Львові за короткий 
          період.
        </p>
      </section>
    </section>
  );
};

export default About;
