import logo from '../assets/logo.webp';
import logoFooter from '../assets/logo_footer.webp';
import maincar from '../assets/maincar.webp';
import bigLetterY from '../assets/big_letter_y.webp';
import headerBg from '../assets/header_bg.webp';
import viber from '../assets/viber.webp';
import locationsBg from '../assets/locations_bg.webp';
import photoGallery1 from '../assets/PhotoGallery/DJI_0039-Edit.webp'
import photoGallery2 from '../assets/PhotoGallery/DJI_0043-Edit.webp'
import photoGallery3 from '../assets/PhotoGallery/DSC09429-Edit.webp'
import photoGallery4 from '../assets/PhotoGallery/DSC09443-Edit.webp'
import photoGallery5 from '../assets/PhotoGallery/DSC09454-Edit.webp'
import photoGallery6 from '../assets/PhotoGallery/DSC09473-Edit.webp'
import photoGallery7 from '../assets/PhotoGallery/DSC09479-Edit.webp'
import photoGallery8 from '../assets/PhotoGallery/DSC09485-Edit.webp'
import photoGallery9 from '../assets/PhotoGallery/DSC09570-Edit.webp'
import photoGallery10 from '../assets/PhotoGallery/DSC09583-Edit.webp'
import photoGallery11 from '../assets/PhotoGallery/DSC09592-Edit.webp'
import photoGallery12 from '../assets/PhotoGallery/DSC09612-Edit.webp'
import photoGallery13 from '../assets/PhotoGallery/DSC09621-Edit.webp'
import photoGallery14 from '../assets/PhotoGallery/DSC05366.webp'
import photoGallery15 from '../assets/PhotoGallery/DSC05430.webp'
import photoGallery16 from '../assets/PhotoGallery/DSC05478.webp'
import photoGallery17 from '../assets/PhotoGallery/DSC05535.webp'
import photoGallery18 from '../assets/PhotoGallery/IMG_7539.webp'
import photoGallery19 from '../assets/PhotoGallery/IMG_7545.webp'
import photoGallery20 from '../assets/PhotoGallery/IMG_7595.webp'
import photoGallery21 from '../assets/PhotoGallery/IMG_7601.webp'
import sportCar from '../assets/car_sport.webp';
import instructor from '../assets/instructor.webp';
import lecture from '../assets/lecture.webp';
import map from '../assets/map1.webp';
import check from '../assets/100.webp';
import pack1 from '../assets/1.webp';
import pack2 from '../assets/2.webp';
import pack3 from '../assets/3.webp';
import moneyBack from '../assets/money-back.webp';
import signsEducation from '../assets/signs_education.webp';
import ford from '../assets/ford.webp';
import megan from '../assets/megan.webp';
import educationWeb from '../assets/education_web.webp';
import educationMans from '../assets/education_mans.webp';
import educationMalko from '../assets/education_malko.webp';
import educationCar from '../assets/education_car.webp';
import educationMegan from '../assets/education_megan.webp';
import educationMegan2 from '../assets/education_megan2.webp';
import documentIdentificationCode from '../assets/document-identificationcode.webp';
import documentPassport from '../assets/document-passport.webp';
import documentMedical from '../assets/document-medical.webp';
import documentPhoto from '../assets/document-photo.webp';
import documentRegistrationPlace from '../assets/document-registrationplace.webp';
import advantageMap from '../assets/map.webp';
import advantageLaptop from '../assets/laptop.svg';
import advantageMoney from '../assets/money.svg';
import advantageMan from '../assets/man.svg';
import priceBicyclePhoto from '../assets/price-bicycle.webp';
import priceDrivingLessonPhoto from '../assets/price-driving-lesson.webp';
import priceLecturePhoto from '../assets/price-lecture.webp';
import publicContract from '../assets/Documents/public-contract.pdf'

export default { 
    logo, 
    maincar, 
    bigLetterY, 
    headerBg, 
    viber, 
    locationsBg,
    photoGallery1,
    photoGallery2,
    photoGallery3,
    photoGallery4,
    photoGallery5,
    photoGallery6,
    photoGallery7,
    photoGallery8,
    photoGallery9,
    photoGallery10,
    photoGallery11,
    photoGallery12,
    photoGallery13,
    photoGallery14,
    photoGallery15,
    photoGallery16,
    photoGallery17,
    photoGallery18,
    photoGallery19,
    photoGallery20,
    photoGallery21,
    sportCar,
    instructor,
    lecture,
    map,
    check,
    pack1,
    pack2,
    pack3,
    moneyBack,
    signsEducation,
    ford,
    megan,
    educationWeb,
    educationMans,
    educationMalko,
    educationCar,
    educationMegan,
    educationMegan2,
    documentIdentificationCode,
    documentPassport,
    documentMedical,
    documentPhoto,
    documentRegistrationPlace,
    logoFooter,
    advantageMap,
    advantageLaptop,
    advantageMoney,
    advantageMan,
    priceBicyclePhoto,
    priceDrivingLessonPhoto,
    priceLecturePhoto,
    publicContract
 };