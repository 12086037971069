// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app__learningStages {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  position: relative;
  padding: 4rem 3rem 4rem;
  width: 100%;
}
@media screen and (max-width: 950px) {
  .app__learningStages {
    padding: 4rem 2rem 3rem;
  }
}
.app__learningStages p {
  color: var(--secondary-color);
  font-size: 1.5rem;
  text-transform: uppercase;
  font-weight: 800;
}
.app__learningStages-title {
  margin-bottom: 2rem;
}
.app__learningStages-list {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-top: 2rem;
  gap: 1rem;
}
@media screen and (max-width: 949px) {
  .app__learningStages-list {
    display: none;
  }
}
.app__learningStages-mobile-list {
  display: flex;
  width: 100%;
}
.app__learningStages-mobile-list-item {
  width: 18rem;
  padding-bottom: 2rem;
}
@media screen and (min-width: 950px) {
  .app__learningStages-mobile-list {
    display: none;
  }
}
.app__learningStages-mobile-list .swiper-pagination .swiper-pagination-bullet {
  background-color: var(--secondary-color);
}
.app__learningStages-mobile-list .swiper-pagination .swiper-pagination-bullet-active {
  background-color: var(--secondary-color);
}`, "",{"version":3,"sources":["webpack://./src/container/LearningStages/LearningStages.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,eAAA;EACA,kBAAA;EACA,uBAAA;EACA,WAAA;AACJ;AACI;EARJ;IASQ,uBAAA;EAEN;AACF;AAAI;EACI,6BAAA;EACA,iBAAA;EACA,yBAAA;EACA,gBAAA;AAER;AACI;EACI,mBAAA;AACR;AAEI;EACI,aAAA;EACA,mBAAA;EACA,6BAAA;EACA,iBAAA;EACA,SAAA;AAAR;AAEQ;EAPJ;IAQQ,aAAA;EACV;AACF;AAEI;EACI,aAAA;EACA,WAAA;AAAR;AAEQ;EACI,YAAA;EACA,oBAAA;AAAZ;AAGQ;EATJ;IAUQ,aAAA;EAAV;AACF;AAGY;EACI,wCAAA;AADhB;AAIY;EACI,wCAAA;AAFhB","sourcesContent":[".app__learningStages {\n    display: flex;\n    flex-direction: column;\n    flex-wrap: wrap;\n    position: relative;\n    padding: 4rem 3rem 4rem;\n    width: 100%;\n\n    @media screen and (max-width: 950px) {\n        padding: 4rem 2rem 3rem;\n    }\n\n    p {    \n        color: var(--secondary-color);\n        font-size: 1.5rem;\n        text-transform: uppercase;\n        font-weight: 800;\n    }\n\n    &-title {\n        margin-bottom: 2rem;\n    }\n\n    &-list {\n        display: flex;\n        flex-direction: row;\n        justify-content: space-around;\n        padding-top: 2rem;\n        gap: 1rem;\n\n        @media screen and (max-width: 949px) {\n            display: none;\n        }\n    }\n\n    &-mobile-list {\n        display: flex;\n        width: 100%;\n\n        &-item {\n            width: 18rem;\n            padding-bottom: 2rem;\n        }\n\n        @media screen and (min-width: 950px) {\n            display: none;\n        }\n\n        .swiper-pagination {\n            .swiper-pagination-bullet {\n                background-color: var(--secondary-color);\n            }\n\n            .swiper-pagination-bullet-active {\n                background-color: var(--secondary-color);\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
