// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-container {
  height: 35rem;
  width: 100%;
  position: relative;
  background-color: #ffffff;
  overflow: hidden;
  box-shadow: 0 0 10px gray;
  display: grid;
  grid-template-rows: 55% 10% 5% 20% 10%;
  grid-template-areas: "img-section" "name-section" "title-section" "desc-section" "social-section";
  text-align: center;
}
.card-container:hover {
  box-shadow: 0 0 20px gray;
}
.card-container .img-card-section {
  grid-area: img-section;
  overflow: hidden;
  position: relative;
}
.card-container .img-card-section .img-card-square {
  background-color: #ffffff;
  height: 25rem;
  width: 10rem;
  position: absolute;
  transform: rotate(70deg);
  top: 6rem;
  left: 5rem;
}
.card-container .img-card-section .img-card-style {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.card-container .name-card-section {
  grid-area: name-section;
  font-size: 1.8rem;
}
.card-container .title-card-section {
  grid-area: title-section;
  font-size: 1.2rem;
  color: darkgray;
}
.card-container .desc-card-section {
  margin: 0.5rem 1.5rem;
}
.card-container .profile-img-card {
  position: absolute;
  height: 7rem;
  width: 7rem;
  box-shadow: 0 0 0 5px #ffffff;
  border-radius: 50%;
  top: calc(50% - 7rem);
  left: calc(50% - 3.2rem);
  object-fit: cover;
}`, "",{"version":3,"sources":["webpack://./src/components/AdvantageCard/AdvantageCard.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,WAAA;EACA,kBAAA;EACA,yBANU;EAOV,gBAAA;EACA,yBAAA;EACA,aAAA;EACA,sCAAA;EACA,iGAAA;EACA,kBAAA;AADF;AAGE;EACE,yBAAA;AADJ;AAIE;EACE,sBAAA;EACA,gBAAA;EACA,kBAAA;AAFJ;AAII;EACE,yBAxBM;EAyBN,aAAA;EACA,YAAA;EACA,kBAAA;EACA,wBAAA;EACA,SAAA;EACA,UAAA;AAFN;AAKI;EACE,YAAA;EACA,WAAA;EACA,iBAAA;AAHN;AAOE;EACE,uBAAA;EACA,iBAAA;AALJ;AAQE;EACE,wBAAA;EACA,iBAAA;EACA,eAAA;AANJ;AASE;EACE,qBAAA;AAPJ;AAUE;EACE,kBAAA;EACA,YAAA;EACA,WAAA;EACA,6BAAA;EACA,kBAAA;EACA,qBAAA;EACA,wBAAA;EACA,iBAAA;AARJ","sourcesContent":["$cardColor: #ffffff;\n\n.card-container{\n  height: 35rem;\n  width: 100%;\n  position: relative;\n  background-color: $cardColor;\n  overflow: hidden;\n  box-shadow: 0 0 10px gray;\n  display: grid;\n  grid-template-rows: 55% 10% 5% 20% 10%;\n  grid-template-areas: \"img-section\" \"name-section\" \"title-section\" \"desc-section\" \"social-section\";\n  text-align: center;\n\n  &:hover{\n    box-shadow: 0 0 20px gray;\n  }\n\n  .img-card-section{\n    grid-area: img-section;\n    overflow: hidden;\n    position: relative;\n\n    .img-card-square{\n      background-color: $cardColor;\n      height: 25rem;\n      width: 10rem;\n      position: absolute;\n      transform: rotate(70deg);\n      top: 6rem;\n      left: 5rem;\n    }\n\n    .img-card-style{\n      height: 100%;\n      width: 100%;\n      object-fit: cover;\n    }\n  }\n\n  .name-card-section{\n    grid-area: name-section;\n    font-size: 1.8rem;\n  }\n\n  .title-card-section{\n    grid-area: title-section;\n    font-size: 1.2rem;\n    color: darkgray;\n  }\n\n  .desc-card-section{\n    margin: .5rem 1.5rem;\n  }\n\n  .profile-img-card{\n    position: absolute;\n    height: 7rem;\n    width: 7rem;\n    box-shadow: 0 0 0 5px $cardColor;\n    border-radius: 50%;\n    top: calc(50% - 7rem);\n    left: calc(50% - 3.2rem);\n    object-fit: cover;\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
