// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app__documentCard {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  background-color: rgba(255, 128, 0, 0.85);
  width: 100%;
}
.app__documentCard-img {
  padding: 1rem;
}
.app__documentCard-text {
  flex: 1 1;
  padding: 1rem;
  color: var(--white-color);
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 800;
}
.app__documentCard-id {
  padding-right: 2rem;
  font-weight: 900;
  font-size: 3.5rem;
  line-height: 82px;
  text-transform: uppercase;
  color: #FF8000;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}`, "",{"version":3,"sources":["webpack://./src/components/DocumentCard/DocumentCard.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,eAAA;EACA,mBAAA;EACA,yCAAA;EACA,WAAA;AACJ;AACI;EACI,aAAA;AACR;AAEI;EACI,SAAA;EACA,aAAA;EACA,yBAAA;EACA,eAAA;EACA,yBAAA;EACA,gBAAA;AAAR;AAGI;EACI,mBAAA;EACA,gBAAA;EACA,iBAAA;EACA,iBAAA;EACA,yBAAA;EACA,cAAA;EACA,4CAAA;AADR","sourcesContent":[".app__documentCard {\n    display: flex;\n    flex-direction: row;\n    flex-wrap: wrap;\n    align-items: center;\n    background-color: rgba(255,128,0,0.85);\n    width: 100%;\n\n    &-img {\n        padding: 1rem;\n    }\n\n    &-text {\n        flex: 1;\n        padding: 1rem;\n        color: var(--white-color);\n        font-size: 1rem;\n        text-transform: uppercase;\n        font-weight: 800;\n    }\n\n    &-id {\n        padding-right: 2rem;\n        font-weight: 900;\n        font-size: 3.5rem;\n        line-height: 82px;\n        text-transform: uppercase;\n        color: #FF8000;\n        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
