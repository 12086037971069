import React from 'react'

import './Discount.scss';

const Discount = () => {
  const menuItems = [
  "«водою не розіллєш» записуйся в автошколу разом із другом, і отримуйте 500 гривень знижки кожен", 
  "знижка для студентів 500 гривень", 
  "знижка на корпоративне навчання 500 гривень", 
  "витягни приємний бонус на першому організаційному навчанні" ];

  return (
    <div className="app__discount">
      {menuItems.map((item) => (
        <div className="app__discount-item">
          <p>{item}</p>
        </div>
      ))}
    </div>
  )
}

export default Discount