import React, { useContext } from 'react';
import './PriceCard.scss';
import AppContext from '../../context/AppContext';
import { motion } from 'framer-motion';

const PriceCard = (props) => {
  const { index, title, desc, priceTitle, oldPrice, price, subPrices, buttonText, imageSrc } = props;
  const { toggleVisibility } = useContext(AppContext);
  const cardVariants= {
    offscreen: {
      y: 30,
      scale: 0.1
    },
    onscreen: {
      y: 0,
      scale: 1,
      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 0.8
      }
    }
  };

  return (
    <motion.div className="pricecard-container" 
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: false, amount: 0.05 }}>
        <section className="img-card-section">
          <img alt="background" className="img-card-style" src={imageSrc} loading="lazy"></img>
        </section>
      <motion.section variants={cardVariants} className="name-card-section">
        {title}
      </motion.section>
      <motion.section variants={cardVariants} className="desc-card-section">
        {desc}
      </motion.section>
      <motion.section variants={cardVariants} className="title-card-section">
        {priceTitle} <s>{oldPrice}</s> <span className="old-price">{price}</span>
      </motion.section>
      <motion.section variants={cardVariants} className="subprices-card-section">
        <ul className="sub-prices">
          {subPrices.map((subPrice, index) => (
            <li key={index}>{subPrice}</li>
          ))}
        </ul>
      </motion.section>
      <section className="button-card-section">
        <button onClick={toggleVisibility} className="price-card__footer-button">{buttonText}</button>
      </section>
    </motion.div>
  );
};

export default PriceCard;
