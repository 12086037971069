// regionsData.js
export const regionsData = [
    {
      title: "Автошкола Сихівський район",
      info: "Наші курси водіїв Сихівський район – ідеальний вибір для тих, хто мешкає на Сихові. Зручне розташування, досвідчені інструктори та сучасні автомобілі зроблять ваше навчання комфортним.",
      list: [
        "Автошкола на Сихові",
        "Курси водіїв на Сихові",
        "Автошкола на проспекті Червоної Калини",
        "Автошкола на Довженка",
        "Автошкола на Зубрівській"
      ]
    },
    {
      title: "Автошкола Франківський район",
      info: "Для мешканців цього району працює автокурси Франківський район, яка пропонує автокурси на Науковій та інших зручних локаціях.",
      list: [
        "Автошкола на Науковій",
        "Автошкола на Володимира Великого",
        "Автошкола на Княгині Ольги",
        "Автошкола на вулиці Стрийській"
      ]
    },
    {
      title: "Автошкола Залізничний район",
      info: "Якщо вам потрібна автошкола Залізничний район, ми пропонуємо навчання з професійними інструкторами та можливість обрати авто з механічною або автоматичною коробкою передач.",
      list: [
        "Автошкола на Городоцькій",
        "Автошкола на Широкій",
        "Автошкола на Любінській",
        "Автошкола на Повітряній"
      ]
    },
    {
      title: "Автошкола Личаківський район",
      info: "Шукаєте автокурси Личаківський район? У нас є навчальні класи та полігони, зокрема автошкола Личаківська, де можна пройти повний курс підготовки водіїв.",
      list: [
        "Автошкола на Зеленій",
        "Автошкола на Личаківській",
        "Автошкола на Пасічній",
        "Автошкола на Тракт Глинянський",
        "Автошкола на Вашингтона"
      ]
    },
    {
      title: "Автошкола Шевченківський район",
      info: "Для мешканців цієї частини Львова доступна автокурси Шевченківський район із зручними філіями, зокрема автокурси на Мазепи, чорновола, замарстинівській – чудово підійдуть для мешканців Шевченківського району.",
      list: [
        "Автошкола на Чорновола",
        "Автошкола на Мазепи",
        "Автошкола на Замарстинівській",
        "Автошкола на Хмельницького"
      ]
    },
    {
      title: "Автошкола Галицький район",
      info: "Якщо вам потрібна автошкола в центрі, щоб здати на права – обирайте навчання у самому серці міста. Доступні автокурси в центрі, що дозволяють легко поєднувати навчання з вашим розкладом.",
      list: [
        "Автошкола на площі Ринок",
        "Автошкола на Дорошенка",
        "Автошкола на Коперника",
        "Автошкола на Січових Стрільців"
      ]
    }
  ];
  